body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body-container {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
}

@media print, screen and (min-width: 40em) {
  .body-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

