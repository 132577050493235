.body-container {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
}

@media print, screen and (min-width: 40em) {
  .body-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
